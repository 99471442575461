import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { HamburgerMenuIcon } from './HamburgerMenuIcon';
import { menuItems } from './const';
import { ButtonTeamup } from './ButtonTeamup';
import { CookieManager } from '../CookieManager';
import { Facebook } from '../Facebook';
import { Instagram } from '../Instagram';

interface HamburgerMenuProps {
}

const HamburgerMenuContainer = styled.div<{
  open: boolean;
  inSchoolPage?: boolean;
  inTeamsPage?: boolean;
}>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 1000px;
  pointer-events: ${({ open }) => open ? 'auto' : 'none'};
  color: ${({ inSchoolPage }) => inSchoolPage
    ? '#000000'
    : '#FFFFFF'
  };
  background-color: ${({ inSchoolPage, theme }) => inSchoolPage
    ? theme.palette.yellowSchool
    : '#FD5D27'
  };
  padding: 2em 0;
  clip-path: ${({ open }) => open
    ? 'inset(0 round 0px)'
    : 'inset(1em 1em calc(100% - 4.5em) calc(100% - 4.5em) round calc(0.625vw + 22px))'
  };
  font-size: calc(0.375vw + 12.8px);
  transition: clip-path 0.2s ease, background-color 0.5s ease-out;
`;

interface MenuItemProps {
  active?: boolean;
  school?: boolean;
  inSchoolPage?: boolean;
}
const MenuItem = styled(({ active, school, inSchoolPage, ...props }) => <Link {...props} />)<MenuItemProps>`
  display: block;
  position: relative;

  color: inherit;
  font-family: League Spartan;
  font-style: normal;
  font-size: 20px;
  line-height: 2em;
  text-align: center;
  transition: color .1s ease-in-out;

  margin-bottom: 0.75em;

  &:hover {
    cursor: pointer;
    color: ${({ inSchoolPage }) => inSchoolPage
      ? '#FFFFFF'
      : '#FFCBCB'
    };
  }

  &::after {
    content: "";
    background-color: #DD4C1C;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 40px;
    left: calc(50% - 3px);
    border-radius: 3px;
    transform: scale(${({ active }) => active ? 1 : 0});
    opacity: ${({ active }) => active ? 1 : 0};
    transition: all .2s ease-in-out;
  }
`;

const HamburgerMenuIconStyled = styled(HamburgerMenuIcon)`
  position: absolute;
  top: 1em;
  right: 1em;
  width: 3.5em;
  height: 3.5em;
  pointer-events: auto;
`;

const CookieManagerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  margin-top: 4px;
`;

const FacebookLink = styled.a`
  color: inherit;
  margin: 0 0.5em;
`;

const InstagramLink = styled.a`
  color: inherit;
  margin: 0 0.5em;
`;

const CookieManagerStyled = styled(CookieManager)`
  font-size: inherit;
  width: 1.25em;
  height: 1.25em;
  margin: 0 0.5em;
`;

const ButtonTeamupStyled = styled(ButtonTeamup)`
  margin: 2em auto 0 auto;
  width: 189px;
`;

function HamburgerMenu(props: HamburgerMenuProps) {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(false), [location.pathname]);

  const inSchoolPage = location.pathname.includes('school');
  const inTeamsPage = location.pathname.includes('teams');

  return (
    <HamburgerMenuContainer
      open={open}
      inSchoolPage={inSchoolPage}
      inTeamsPage={inTeamsPage}
      {...props}
    >
      {menuItems
        .filter(() => !inTeamsPage)
        .map(({ link, name }) => (
          <MenuItem
            key={link.to}
            active={location.pathname.includes(link.to)}
            school={link.to.includes('school')}
            inSchoolPage={inSchoolPage}
            {...link}
          >
            {name}
          </MenuItem>
        ))
      }
      {inSchoolPage && (
        <ButtonTeamupStyled />
      )}
      <HamburgerMenuIconStyled open={open} inSchoolPage={inSchoolPage} onClick={() => setOpen(current => !current)} />
    </HamburgerMenuContainer>
  );
}

export { HamburgerMenu };
