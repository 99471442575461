import styled from 'styled-components';
import { Hider } from './Hider';

interface DotsDesktopProps {
}

const DotsDesktopContainer = styled.div`
  position: relative;
  @media (max-width: 630px) {
    display: none;
  }
`;

const Dots1 = styled.svg`
  display: block;
  position: relative;
  left: calc(40.729247478665634vw + -212.00155159038013px); /* 1920: 570, 631: 45 */
  margin-top: 100px;
  margin-bottom: calc(4.654771140418929vw + 80.62839410395655px); /* 1920: 170, 631: 110 */
  clip-path: polygon(0 0, calc(10vw + 40%) 0, 80% 100%, 0% 100%);
`;

const Dots2 = styled.svg`
  display: block;
  position: relative;
  left: calc(40.729247478665634vw + -212.00155159038013px); /* 1920: 570, 631: 45 */
  margin-bottom: calc(4.266873545384018vw + 18.076027928626843px); /* 1920: 100, 631: 45 */
`;

const Dots3 = styled.svg`
  display: block;
  position: relative;
  left: calc(60.51202482544609vw + -31.83087664856481px); /* 1920: 1130, 631: 350 */
  margin-bottom: calc(3.8789759503491075vw + 85.52366175329713px); /* 1920: 160, 631: 110 */
`;

const Dots4 = styled.svg`
  display: block;
  position: relative;
  left: calc(57.4088440651668vw + -122.24980605120248px); /* 1920: 980, 631: 240 */
`;

function DotsDesktop(_: DotsDesktopProps) {
  return (
    <DotsDesktopContainer>
      <Dots1 width="212" height="149" viewBox="0 0 212 149" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M210 2C138.834 9.33333 -2.29975 48.6 2.50025 147" stroke="#FD5D27" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1 40"/>
      </Dots1>

      <Dots2 width="150" height="157" viewBox="0 0 150 157" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.49979 2C1.83312 44.8333 29.8998 135.4 147.5 155" stroke="#FD5D27" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1 40"/>
      </Dots2>

      <Dots3 width="142" height="278" viewBox="0 0 142 278" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 2C78.8333 24.8333 205 111.6 99 276" stroke="#FD5D27" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1 40"/>
      </Dots3>

      <Dots4 width="125" height="117" viewBox="0 0 125 117" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M122.405 15.6685C105.311 35.4758 57.5157 80.3384 3.0943 101.331" stroke="#FD5D27" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1 40"/>
      </Dots4>
      <Hider />
    </DotsDesktopContainer>
  );
}

export { DotsDesktop };
