import styled from 'styled-components';
import Price from './Price';

interface PricesProps {
}

const PriceStyled = styled(Price)``;

const PricesContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 300px;
  flex-wrap: wrap;
  margin-bottom: 100px;
  @media (min-width: 630px) {
    display: grid;
    max-width: initial;
    grid-template-columns: repeat(5, 1fr);
    & > ${PriceStyled}:nth-child(-n + 4) {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 100%;
        background: linear-gradient(180deg, rgba(155, 255, 237, 0) 1.56%, rgba(155, 255, 237, 0.3) 48.96%, rgba(155, 255, 237, 0) 100%);
      }
    }
  }
`;

const Separator = styled.div`
  border: 1.5px solid #9BFFED;
  width: 1px;
  height: 65px;
  transform: rotate(15.88deg);
  @media (max-width: 630px) {
    display: none;
  }
`;

function Prices(_: PricesProps) {
  return (
    <PricesContainer>
      <PriceStyled
        title='1 clase'
        price='20€'
      />
      <PriceStyled
        title='1 mes'
        price='55€'
      />
      <PriceStyled
        title='3 meses'
        price='150€'
      />
      <PriceStyled
        title='4 clases'
        price='70€'
        subtitle='Válido 2 meses'
      />
      <PriceStyled
        title='8 clases'
        price='120€'
        subtitle='Válido 4 meses'
      />
    </PricesContainer>
  );
}

export default Prices;
