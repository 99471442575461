import styled from 'styled-components';
import packBox from '../../assets/images/packBox.svg';
import arrows from './arrows.svg';

interface CustomizePackProps {
}

const CustomizePackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
`;

const Text = styled.p`
  font-family: League Spartan;
  font-weight: bold;
  text-align: center;
  max-width: 140px;
`;

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 1em;
`;
const Arrows = styled.img`
  position: absolute;
  top: -4em;
  left: -3em;
`;
const Box = styled.img`
  width: 100px;
`;

function CustomizePack(_: CustomizePackProps) {
  return (
    <CustomizePackContainer>
      <ImageContainer>
        <Arrows src={arrows} alt='Arrows pointing into a box' />
        <Box src={packBox} alt='Box' />
      </ImageContainer>
      <Text>
        Or you can also customize your own pack
      </Text>
    </CustomizePackContainer>
  );
}

export { CustomizePack };
