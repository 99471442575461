import { MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import online from '../../assets/images/online.svg';
import inPerson from '../../assets/images/inPerson.svg';

export enum BadgeType {
  Online,
  FaceToFace,
}

interface BadgeProps {
  type: BadgeType;
}

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.orange};
  border-radius: 9px;
  padding: 0px 6px;
  margin: 0 0.25em;
`;

const Text = styled.span`
  font-family: ${({ theme }) => theme.font.quicksand};
  font-size: 9px;
  white-space: nowrap;
`;

const Icon = styled.img`
  margin: -1px 0 0 0.25em;
`;

const texts = {
  [BadgeType.Online]: 'online',
  [BadgeType.FaceToFace]: 'face-to-face',
};

const icons = {
  [BadgeType.Online]: {
    src: online,
    alt: 'Online',
  },
  [BadgeType.FaceToFace]: {
    src: inPerson,
    alt: 'Face-to-face',
  },
};

function Badge({ type }: BadgeProps) {
  const text = texts[type];
  const icon = icons[type];

  return (
    <BadgeContainer>
      <Text>{text}</Text>
      <Icon src={icon.src} alt={icon.alt} />
    </BadgeContainer>
  );
}

export { Badge };
