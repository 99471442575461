import styled from 'styled-components';
import Head from './Head';
import { Coaches } from './Coaches';
import { Values } from './Values';
import { ContactUs } from './ContactUs';
import { CreativePotential } from './CreativePotential';
import { Packs } from './Packs';
import { SectionHeader } from '../../components/SectionHeader';
import { Hero } from '../../components/Hero';
import { HeroImage, AspectRatioBox } from '../../components/Hero/HeroImage';
import { Footer } from '../../components/Footer';

import hero from './assets/hero.jpg';
// @ts-ignore
import heroPlaceholder from './assets/heroPlaceholder.jpg?inline';

interface TeamsProps {
}

const TeamsContainer = styled.div`
`;

const Content = styled.div`
  position: relative;
  top: 100vh;
  padding: calc(-1.875vw + 36px); /* 1920: 0, 320: 30 */
  padding-bottom: 200px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  display: flex;

  font-family: League Spartan;
  font-weight: 700;
  font-size: calc(0.5vw + 23.4px); /* 1920: 33, 320: 25 */
  line-height: 1.25em;

  position: relative;
  left: calc(-31.690140845070424vw + 158.45070422535218px); /* 500: 0, 1920: -450 */

  padding: 0 calc(40.625vw + -80px); /* 1920: 700, 320: 50 */

  @media (max-width: 500px) {
    left: 0;
  }

  margin-bottom: 1em;
`;

const HeroImageStyled = styled(HeroImage)`
  ${AspectRatioBox} {
    transform: translateX(min(0px, calc(17.543859649122805vw + -206.140350877193px)));
  }
`;

function Teams(_: TeamsProps) {
  return (
    <TeamsContainer>
      <Head />
      <Hero title='“Succesful solo acts are rarely, in fact, solo”'>
        <HeroImageStyled
          src={hero}
          alt='Teambuilding workshop'
          opacity={0.8}
          placeholder={heroPlaceholder}
        />
      </Hero>
      <Content>
        <CreativePotential />
        <Title>
          With ESIMPRO’s workshops you can develop your:
        </Title>
        <Values />
        <SectionHeader
          title='Which are the needs of your team?'
          description='Think out of the box'
          separator
        />
        <Packs />
        <Coaches />
        <ContactUs />
        <Footer />
      </Content>
    </TeamsContainer>
  );
}

export { Teams };
