import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TeamsPackModalPropsInternal } from '../components/TeamsPackModal/TeamsPackModal';
import { useServices } from './useServices';
import { getStorageKey, getStoredValue } from './utils';

const apiUrl = process.env.REACT_APP_API_URL;

type ProvidedProps = 'open' | 'onClose';
type HookProps = Pick<TeamsPackModalPropsInternal, ProvidedProps>;

function useTeamsPackModal(): [HookProps, () => void] {
  const [isOpen, setOpen] = useState(false);

  const open = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => setOpen(false), []);

  const modalProps = useMemo(() => ({
    open: isOpen,
    onClose,
  }), [isOpen, onClose]);

  return [modalProps, open];
}

export { useTeamsPackModal };
