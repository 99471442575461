import styled from 'styled-components';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { menuItems } from './const';
import { ButtonTeamup } from './ButtonTeamup';
import { CookieManager } from '../CookieManager';
import { Facebook } from '../Facebook';
import { Instagram } from '../Instagram';

interface InlineMenuProps {
}

const InlineMenuContainer = styled.div`
  padding: 2em 3vw 0 0;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 1em;
`;

interface MenuItemProps {
  active?: boolean;
  school?: boolean;
}
const MenuItem = styled(({ active, school, ...props }) => <Link {...props} />)<MenuItemProps>`
  position: relative;
  pointer-events: auto;

  color: #FFFFFF;
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: calc(0.25210084033613445vw + 8.159663865546218px); /* 1920: 13, 730: 10 */
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  transition: color .1s ease-in-out;

  margin-left: calc(3.2773109243697482vw + -10.92436974789916px); /* 1920: 52, 730: 13 */

  &:hover {
    cursor: pointer;
    color: #FD5D27;
  }

  &::before {
  }

  &::after {
    content: "";
    background-color: ${({ school, theme }) => school
      ? theme.palette.yellowSchool
      : '#FD5D27'
    };
    width: 6px;
    height: 6px;
    position: absolute;
    top: 22px;
    left: calc(50% - 3px);
    border-radius: 3px;
    transform: scale(${({ active }) => active ? 1 : 0});
    opacity: ${({ active }) => active ? 1 : 0};
    transition: all .2s ease-in-out;
  }
`;

const FacebookLink = styled.a`
  margin-left: 1em;
  margin-top: -2px;
  color: white;
`;

const InstagramLink = styled.a`
  margin-left: 1em;
  margin-top: -2px;
  color: white;
`;

const CookieManagerStyled = styled(CookieManager)`
  width: 20px;
  height: 20px;
  margin-left: 1em;
  margin-top: -3px;
`;

function InlineMenu(props: InlineMenuProps) {
  const location = useLocation();
  const inSchoolPage = location.pathname.includes('school');
  const inTeamsPage = location.pathname.includes('teams');

  return (
    <InlineMenuContainer {...props}>
      <Row>
        {menuItems
          .filter(() => !inTeamsPage)
          .map(({ link, name }) => (
            <MenuItem
              key={link.to}
              active={location.pathname.includes(link.to)}
              school={link.to.includes('school')}
              {...link}
            >
              {name}
            </MenuItem>
          ))
        }
      </Row>
      {inSchoolPage && (
        <Row>
          <ButtonTeamup dark />
        </Row>
      )}
    </InlineMenuContainer>
  );
}

export { InlineMenu };
