import styled from 'styled-components';
import Workshop from './Workshop';
import { WorkshopTypes } from './types';
import { getWorkshopImage } from './utils';

interface WorkshopsProps {
}

const WorkshopsContainer = styled.div`
  margin-top: 80px;
  display: grid;

  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1042px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 784px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 526px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 785px) {
    padding: 0 calc(15.418502202643172vw + -121.0352422907489px); /* 1920: 175, 785: 0 */
  }
`;

const workshops = [
  {
    id: WorkshopTypes.EscenasDeCine,
    title: 'Escenas de Cine',
    description: 'Descubre tu artista interior.',
    image: getWorkshopImage(WorkshopTypes.EscenasDeCine),
  },
  {
    id: WorkshopTypes.MeditacionPractica,
    title: 'Meditación práctica',
    description: 'Donde practicar las técnicas para meditar.',
    image: getWorkshopImage(WorkshopTypes.MeditacionPractica),
  },
  {
    id: WorkshopTypes.FinalesDeImpro,
    title: 'Finales de Impro',
    description: 'Empezando por el final de la improvisación',
    image: getWorkshopImage(WorkshopTypes.FinalesDeImpro),
  },
  {
    id: WorkshopTypes.ReConecta,
    title: 'Re´conecta',
    description: 'Un taller donde el arte te reconectará',
    image: getWorkshopImage(WorkshopTypes.ReConecta),
  },
  {
    id: WorkshopTypes.TallerParaDocentes,
    title: 'Taller para Docentes',
    description: 'Aprender a enseñar también es impro',
    image: getWorkshopImage(WorkshopTypes.TallerParaDocentes),
  },
  {
    id: WorkshopTypes.Superimproheroes,
    title: 'ESImproHeroes',
    description: 'Learning how to be an actor and an improviser.',
    subtitle: 'con Paula Galimberti',
    image: getWorkshopImage(WorkshopTypes.Superimproheroes),
  },
  {
    id: WorkshopTypes.Babel,
    title: 'Babel Impro',
    description: 'La impro “multiculti” que llega a todo el mundo.',
    image: getWorkshopImage(WorkshopTypes.Babel),
  },
  {
    id: WorkshopTypes.Improcampa,
    title: 'ESimproCampa',
    description: 'Un retiro improvisado, un paraíso de la impro.',
    image: getWorkshopImage(WorkshopTypes.Improcampa),
  },
];


function Workshops(_: WorkshopsProps) {
  return (
    <WorkshopsContainer>
      {workshops.map(workshop => (
        <Workshop key={workshop.id} {...workshop} />
      ))}
    </WorkshopsContainer>
  );
}

export default Workshops;
