import styled from 'styled-components';
import Head from './Head';
import Courses from './Courses';
import Prices from './Prices';
import Teachers from './Teachers';
import Workshops from './Workshops';
import { UpcomingEvents } from './UpcomingEvents';
import { SectionHeader } from '../../components/SectionHeader';
import { Hero } from '../../components/Hero';
import { Footer } from '../../components/Footer';

import heart from './assets/heart.svg';
import hero from './hero.jpg';
// @ts-ignore
import heroPlaceholder from './heroPlaceholder.jpg?inline';

interface SchoolProps {
}

const SchoolContainer = styled.div`
`;

const Content = styled.div`
  position: relative;
  top: 100vh;
  padding: calc(-1.875vw + 36px); /* 1920: 0, 320: 30 */
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Flag = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const Notice = styled.p`
  font-family: League Spartan;
  font-weight: bold;
  font-size: 14px;

  /* white */
  color: #FFFFFF;
`;

const Separator = styled.span`
  height: 1px;
  width: 100%;
  background-image: linear-gradient(90deg, #9BFFED00 0, #9BFFEDFF 50%, #9BFFED00 100%);
  margin: 2em 0 3em 0;
  opacity: 0.4;
`;

const WishlistExplainer = styled.div`
  color: ${({ theme }) => theme.palette.yellowSchool};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  padding: 0px calc(8.75vw + -28px); /* 1920: 140, 320: 0 */

  @media (max-width: 630px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Heart = styled.img`
  margin: 0 1em 0 0;

  @media (max-width: 630px) {
    margin: 0 0 1em 0;
  }
`;

function School(_: SchoolProps) {
  return (
    <SchoolContainer>
      <Head />
      <Hero
        title='Aprende lo que sea, en esimpro escuela'
        image={{
          src: hero,
          alt: 'Comunidad de la Improescuela',
          opacity: 0.7,
        }}
      />
      <Content>
        <SectionHeader
          title='Agenda'
          description='Aquí tienes todo lo programado'
          separator
          variant='yellow'
        />
        <UpcomingEvents />
        <SectionHeader
          title='Clases regulares'
          description='Elije tu curso y tu tipo de abono'
          separator
          variant='yellow'
        />
        <Courses />
        <Notice>*los horarios pueden cambiar</Notice>
        <Separator />
        <Prices />
        <SectionHeader
          title='Todos los workshops'
          description='Intensivos, voz, cuerpo, mente, interpretación... ¿Cuál es el tuyo?'
          separator
          variant='yellow'
        />
        <WishlistExplainer>
          <Heart src={heart} alt='Icono de corazón' />
          Si te interesa algún curso, toca su corazón e intentaremos programarlo
        </WishlistExplainer>
        <Workshops />
        <SectionHeader
          title='Equipo'
          description='Cuando no improvisan, enseñan'
          separator
          variant='yellow'
        />
        <Teachers />
        <Footer />
      </Content>
    </SchoolContainer>
  );
}

export { School };
