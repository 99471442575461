import styled from 'styled-components';
import { Pack } from './Pack';
import { CustomizePack } from './CustomizePack';
import { BadgeType } from '../../components/TeamsPackModal';

import boxCollab from './assets/boxCollab.svg';
import boxCohesion from './assets/boxCohesion.svg';
import boxInnovation from './assets/boxInnovation.svg';
import boxDiscovery from './assets/boxDiscovery.svg';
import boxArts from './assets/boxArts.svg';
import boxRegularClasses from './assets/boxRegularClasses.svg';

import insights from './assets/insights.png';
import expressiveArts from './assets/expressiveArts.png';

interface PacksProps {
}

const PacksContainer = styled.div`
  display: grid;
  padding: 0px calc(10.125vw + -32.400000000000006px); /* 1920: 162, 320: 0 */

  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }

  margin: 5em 0 8em 0;
`;

const Insights = styled.img`
  width: 129px;
  height: 34px;
`;

const ExpressiveArts = styled.img`
  width: 33px;
  height: 27px;
`;

function Packs(_: PacksProps) {
  return (
    <PacksContainer>
      <Pack
        title='Improv’ing Collaboration'
        box={boxCollab}
        badges={[BadgeType.Online, BadgeType.FaceToFace]}
        duration={'3 - 6 H'}
        bullets={[
          'Listening and accepting',
          'Co-creation',
          'Empathy',
        ]}
        useCases="This session is recommended to intact, leaderships and ad-hoc teams, both newly formed and tenured. Team summits, change management, resilience or re-boosting team's morale are only some of the possible applications of this workshop."
        learningOutcomes={[
          'Identify patters that hinder collaboration.',
          'Improve effective listening.',
          'Foster co-creation. Build trust on teammates contributions.',
          'Increase resilience and spontaneity when facing changes or failure.',
        ]}
      />
      <Pack
        title='Improv’ing Cohesion'
        box={boxCohesion}
        badges={[BadgeType.FaceToFace]}
        duration={'3 - 5 H'}
        bullets={[
          'Team-building',
          'Trust and inclusion',
          'Fun',
        ]}
        useCases='This experience is recommended to both new and tenured teams, but preferably intact teams, as it focuses on team bonds. Be it in the context of a reenergizing off-site or a yearly kick off, your teams will laugh and create together.'
        learningOutcomes={[
          'Strengthen team bonds through co-creation and fun.',
          'Improve listening skills.',
          'Provide a supporting work enviroment.',
          'Practice how to give and take focus and how to help your partners shine on scene.',
        ]}
      />
      <Pack
        title='Improv’ing Innovation'
        box={boxInnovation}
        badges={[BadgeType.FaceToFace]}
        duration={'3 - 5 H'}
        bullets={[
          'Effective risk taking',
          'Accepting new ideas',
          'Discover beauty in the unexpected',
        ]}
        useCases='This session is recommended to both intact and adhoc teams looking to improve innovation mechanisms.'
        learningOutcomes={[
          'Remove blockers that hinder the generation of new ideas.',
          'Explore "Yes, and..." as a language that supports ideation.',
          'Experience giving and taking the focus of the conversation.',
          'Pratice more readily expressing my ideas in public',
        ]}
      />
      <Pack
        title='Insights Discovery'
        box={boxDiscovery}
        badges={[BadgeType.FaceToFace, BadgeType.Online]}
        duration={'4 - 7 H'}
        titleLogo={(
          <Insights src={insights} alt='Insights' />
        )}
        bullets={[
          'Personal preferences',
          'Self-awareness',
          'Adapting to collaborate',
        ]}
        useCases='Insights Discovery is suitable for all levels and intentions; whether that’s developing themselves, working as part of an effective team, or leading others with authenticity. Individuals will gain a common language that will help connecting colleagues across cultural boundaries, and providing a safe platform for  conversations.'
        learningOutcomes={[
          'Increase team spirit and cohesion.',
          'Support psychological safety at work.',
          'Relief stress and increase resilience.',
          'Foster Self-Discovery as a resource for professional development.',
          'Surface communication patterns that hinder productivity.',
        ]}
      />
      <Pack
        title='Expressive Arts'
        box={boxArts}
        badges={[BadgeType.FaceToFace]}
        duration={'3 - 5 H'}
        bullets={[
          'Foster self-awareness',
          'Increase well-being',
          'Discover internal resources',
        ]}
        titleLogo={(
          <ExpressiveArts src={expressiveArts} alt='Expressive Arts' />
        )}
        useCases='This session is particularly suited to intact teams aiming to employ creative ways to self-discover and leverage those findings to increase team cohesion and empathy.'
        learningOutcomes={[
          'Increase team spirit and cohesion.',
          'Support psychological safety at work.',
          'Relief stress and increase resilience.',
          'Foster Self-Discovery as a resource for professional development .',
          'Surface communication patterns that hinder productivity.',
        ]}
      />
      <Pack
        title='Regular classes'
        box={boxRegularClasses}
        description='Have your company stand out of the crowd by funding onsite Improv classes. For a reasonable price, an ESIMPRO Certified instructor will come to your company for weekly or biweekly Improv classes.'
        badges={[BadgeType.FaceToFace]}
        duration={'2 - 3 H'}
        bullets={[
          'Public speaking',
          'Forging team bond',
          'Personal growth',
        ]}
        useCases='This is recommended for ad-hoc groups of any level that are interested in a regular post-work activity. A minimum of 7 participants is required.'
        learningOutcomes={[
          'Extra curricular activity that will improve public speaking, innovation, listening and collaboration.',
          'Group members will get to connect across different teams.',
        ]}
      />
      <CustomizePack />
    </PacksContainer>
  );
}

export { Packs };
