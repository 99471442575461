import { useContext, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { LayoutContext } from '../../../providers/Layout';
import { useLayoutScroll } from '../../../hooks';

interface HiderProps {
}

const HiderContainer = styled.div.attrs<{ scroll: number }>(
  ({ scroll }) => ({
    style: {
      transform: `translateY(${scroll}px)`,
    },
  })
)<{ scroll: number }>`
  transition: transform 0.5s ease-out;
`;

const Gradient = styled.div`
  background: linear-gradient(0deg,rgba(0,2,39,1) 0%,rgba(0,2,39,0) 100%);
  height: 45px;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const Solid = styled.div.attrs<{ scroll: number }>(
  ({ scroll }) => ({
    style: {
      transform: `scaleY(${scroll})`,
    },
  })
)<{ scroll: number }>`
  background: #000227;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  transform-origin: top;
  transition: transform 0.5s ease-out;
`;

function Hider(_: HiderProps) {
  const { getScroll } = useContext(LayoutContext);
  const scroll = useLayoutScroll();
  const containerTop = useRef(0);

  const position = useMemo(() => (
    Math.min(1300, Math.max(0, scroll.bottom * 1.5 - containerTop.current - 1250))
  ), [scroll]);

  return (
    <HiderContainer scroll={-1200 + position} ref={ref => {
      if (ref && !containerTop.current) {
        containerTop.current = ref.getBoundingClientRect().top + getScroll();
      }
    }}>
      <Gradient />
      <Solid scroll={1200 - position} />
    </HiderContainer>
  );
}

export { Hider };
