import styled from 'styled-components';
import { Hider } from './Hider';

interface DotsMobileProps {
}

const DotsMobileContainer = styled.div`
  position: relative;
  @media (min-width: 631px) {
    display: none;
  }
`;

const Dots1 = styled.svg`
  display: block;
  position: relative;
  left: calc(51.61290322580645vw + -155.16129032258064px); /* 630: 170, 320: 10 */
  margin-top: 100px;
  margin-bottom: 120px;
`;

const Dots2 = styled.svg`
  display: block;
  position: relative;
  left: calc(67.74193548387096vw + -46.774193548387075px); /* 630: 380, 320: 170 */
  margin-bottom: 100px;
`;

const Dots3 = styled.svg`
  display: block;
  position: relative;
  left: calc(35.483870967741936vw + -83.5483870967742px); /* 630: 140, 320: 30 */
  margin-bottom: calc(6.451612903225806vw + 49.35483870967742px); /* 630: 90, 320: 70 */
`;

const Dots4 = styled.svg`
  display: block;
  position: relative;
  left: calc(54.83870967741935vw + 24.51612903225808px); /* 630: 370, 320: 200 */
  margin-bottom: 90px;
`;

const Dots5 = styled.svg`
  display: block;
  position: relative;
  left: calc(29.03225806451613vw + -22.903225806451616px); /* 630: 160, 320: 70 */
`;

function DotsMobile(_: DotsMobileProps) {
  return (
    <DotsMobileContainer>
      <Dots1 width="48" height="96" viewBox="0 0 48 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M46.4999 1.5C25.4999 12.3333 -11.8001 46.2 6.99992 95" stroke="#FD5D27" strokeWidth="2" strokeLinecap="round" strokeDasharray="1 20"/>
      </Dots1>

      <Dots2 width="29" height="86" viewBox="0 0 29 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 1.5C39.1667 12 28.5 54.5 1.5 84.5" stroke="#FD5D27" strokeWidth="2" strokeLinecap="round" strokeDasharray="1 20"/>
      </Dots2>

      <Dots3 width="35" height="106" viewBox="0 0 35 106" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.4999 1C9.33323 20 -24.5001 67.4 33.4999 105" stroke="#FD5D27" strokeWidth="2" strokeLinecap="round" strokeDasharray="1 20"/>
      </Dots3>

      <Dots4 width="41" height="136" viewBox="0 0 41 136" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 1.5C14 8 78.4 76.6 6 135" stroke="#FD5D27" strokeWidth="2" strokeLinecap="round" strokeDasharray="1 20"/>
      </Dots4>

      <Dots5 width="18" height="87" viewBox="0 0 18 87" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9998 1C-0.333537 11.5 -7.80019 59.6 16.9998 86" stroke="#FD5D27" strokeWidth="2" strokeLinecap="round" strokeDasharray="1 20"/>
      </Dots5>
      <Hider />
    </DotsMobileContainer>
  );
}

export { DotsMobile };
