import styled from 'styled-components';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { InlineMenu, HamburgerMenu } from './Menu';
import logoEsImpro from '../assets/images/logoEsImpro.png';
import logoEsImproEscuela from '../assets/images/logoEsImproEscuela.png';
import logoEsImproTeams from '../assets/images/logoEsImproTeams.png';

interface HeaderProps {
  isLivestream?: boolean;
}

const HeaderContainer = styled.div<HeaderProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: ${({ isLivestream }) => isLivestream ? '13vh' : 'calc(5.375vw + 153px)' };

  background: linear-gradient(180deg, #000227 0%, rgba(0, 2, 39, 0.770833) 30.73%, rgba(0, 2, 39, 0.255013) 68.23%, rgba(0, 2, 39, 0.129667) 77.08%, rgba(0, 2, 39, 0) 98.96%);
`;

const Logo = styled.div<{ pathname: string }>`
  position: absolute;
  top: 30px;
  left: calc(1.875vw + 24px);
  width: calc(3.5vw + 50px);
  height: 80px;

  background-image: url(${({ pathname }) => (
    pathname.includes('school')
      ? logoEsImproEscuela
      : pathname.includes('teams')
        ? logoEsImproTeams
        : logoEsImpro
  )});
  background-repeat: no-repeat;
  background-size: contain;

  &:hover {
    background-image: url(${logoEsImpro});
  }
`;

const InlineMenuResponsive = styled(InlineMenu)<{ bp: number }>`
  @media (max-width: ${({ bp }) => bp}px) {
    display: none;
  }
`;

const HamburgerMenuResponsive = styled(HamburgerMenu)<{ bp: number }>`
  @media (min-width: ${({ bp }) => bp + 1}px) {
    display: none;
  }
`;

function Menu() {
  const location = useLocation();
  const noMenu = location.pathname.includes('teams');
  const forceHamburger = location.pathname.includes('live');
  const menuBreakpoint = 830;

  return noMenu
    ? null
    : forceHamburger
    ? (
      <HamburgerMenu />
    ) : (
      <>
        <InlineMenuResponsive bp={menuBreakpoint} />
        <HamburgerMenuResponsive bp={menuBreakpoint} />
      </>
    );
}

function Header(_: HeaderProps) {
  const location = useLocation();
  return (
    <HeaderContainer isLivestream={location.pathname.includes('live')}>
      <Link to='/home.html'>
        <Logo pathname={location.pathname} />
      </Link>
      <Menu />
    </HeaderContainer>
  );
}

export { Header };
