import styled from 'styled-components';
import { Value } from './Value';
import { Dots } from './Dots';

interface ValuesProps {
}

const ValuesContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  margin-bottom: 5em;
  width: 100%;
`;

function Values(_: ValuesProps) {
  return (
    <ValuesContainer>
      <Value.EffectiveListening />
      <Value.Trust />
      <Value.CoCreation />
      <Value.SelfDiscovery />
      <Value.Spotaneity />
      <Value.Resilience />
      <Dots />
    </ValuesContainer>
  );
}

export { Values };
