import { HelmetData } from 'react-helmet';

export enum Environment {
  Browser,
  Static,
}

export declare namespace Teamup {
  export interface Event {
    id: number;
    name: string;
    start_time: string;
    end_time: string;
    venue: {
      is_online: boolean;
    };
  }
}

export declare namespace DB {
  export interface Show {
    id: string;
    name: string;
    description: string;
  }
}

export interface Context {
  helmet: {
    helmet: HelmetData;
  };
  router: {
    path: string;
  };
  style: {
    css: Set<string>;
  };
  data: {
    teamupEvents: Array<Teamup.Event>;
    shows: Array<DB.Show>;
  };
}

export enum WishlistTypes {
  ShowFormat = 'showFormat',
  SchoolWorkshop = 'schoolWorkshop',
}

export interface Storage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}
