import styled from 'styled-components';
import { DotsDesktop } from './DotsDesktop';
import { DotsMobile } from './DotsMobile';

interface DotsProps {
}

const DotsContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  z-index: -1;
  width: 100%;
`;

function Dots(_: DotsProps) {
  return (
    <DotsContainer>
      <DotsDesktop />
      <DotsMobile />
    </DotsContainer>
  );
}

export { Dots };
