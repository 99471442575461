import { Helmet } from 'react-helmet-async';

import logoEsImpro from './assets/images/logoEsImpro.png';

import msIcon144 from './assets/favicon/ms-icon-144x144.png';
import appleIcon57 from './assets/favicon/apple-icon-57x57.png';
import appleIcon60 from './assets/favicon/apple-icon-60x60.png';
import appleIcon72 from './assets/favicon/apple-icon-72x72.png';
import appleIcon76 from './assets/favicon/apple-icon-76x76.png';
import appleIcon114 from './assets/favicon/apple-icon-114x114.png';
import appleIcon120 from './assets/favicon/apple-icon-120x120.png';
import appleIcon144 from './assets/favicon/apple-icon-144x144.png';
import appleIcon152 from './assets/favicon/apple-icon-152x152.png';
import appleIcon180 from './assets/favicon/apple-icon-180x180.png';
import androidIcon192 from './assets/favicon/android-icon-192x192.png';
import favicon16 from './assets/favicon/favicon-16x16.png';
import favicon32 from './assets/favicon/favicon-32x32.png';
import favicon96 from './assets/favicon/favicon-96x96.png';
import safariPinnedTab from './assets/favicon/safari-pinned-tab.svg';

interface HeadProps {
}

const Head = (_: HeadProps) => (
  <Helmet>
    <title>ESIMPRO | Improvisación en español</title>
    <meta name="description" content="" />

    <link rel="shortcut icon" href='favicon.ico' />
    <link rel="manifest" href='site.webmanifest' />

    <meta property="og:url" content="https://www.berlinesimpro.com/home.html" />
    <meta property="og:title" content="ESIMPRO | Improvisación en español" />
    <meta property="og:description" content="" />
    <meta property="og:image" content={logoEsImpro} />
    <meta property="og:locale" content="es_ES" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:title" content="ESIMPRO | Improvisación en español" />
    <meta property="twitter:description" content="" />
    <meta property="twitter:image" content={logoEsImpro} />

    <meta name="apple-mobile-web-app-title" content="ESIMPRO" />
    <meta name="application-name" content="ESIMPRO" />
    <meta name="theme-color" content="#E24D39" />

    <meta name="msapplication-TileColor" content="#DA532C" />
    <meta name="msapplication-TileImage" content={msIcon144} />

    <link rel="apple-touch-icon" sizes="57x57" href={appleIcon57} />
    <link rel="apple-touch-icon" sizes="60x60" href={appleIcon60} />
    <link rel="apple-touch-icon" sizes="72x72" href={appleIcon72} />
    <link rel="apple-touch-icon" sizes="76x76" href={appleIcon76} />
    <link rel="apple-touch-icon" sizes="114x114" href={appleIcon114} />
    <link rel="apple-touch-icon" sizes="120x120" href={appleIcon120} />
    <link rel="apple-touch-icon" sizes="144x144" href={appleIcon144} />
    <link rel="apple-touch-icon" sizes="152x152" href={appleIcon152} />
    <link rel="apple-touch-icon" sizes="180x180" href={appleIcon180} />

    <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
    <link rel="icon" type="image/png" sizes="96x96" href={favicon96} />
    <link rel="icon" type="image/png" sizes="192x192" href={androidIcon192} />

    <link rel="mask-icon" color="#E24D39" href={safariPinnedTab} />
  </Helmet>
);

export { Head };
