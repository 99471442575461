import { FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import { getShowFormatImage, getShowFormatType } from './utils';
import { ScrollableContainer } from '../../components/ScrollableContainer';
import { useLayout, useServices } from '../../hooks';
import { Context, DB } from '../../types';

import introduccionALaImpro from './assets/introduccionALaImpro.jpg';
import meditacionPractica from './assets/meditacionPractica.jpg';

interface UpcomingShowsProps {
}

const hardcodedShows: Array<DB.Show> = [
  {
    id: '34865927',
    name: 'Jam',
    description: 'Donde todo el mundo improvisa',
  },
];

const parseDbShow = (show: DB.Show) => ({
  id: show.id,
  name: show.name,
  description: show.description,
  startDate: new Date(),
  endDate: new Date(),
  image: '',
});

const getShows = (data: Context['data']) =>
  process.env.NODE_ENV === 'production'
    ? data.shows
    : hardcodedShows;

function UpcomingShows(props: UpcomingShowsProps) {
  const { data } = useServices();

  const shows = getShows(data)
    .map(parseDbShow);

  return null;
  // TODO ScrollableContainer has teamup-specific hardcoded logic (eg., link URL)
  //   In particular, the Event component is setting the data requirements here
  /*
    .filter(({ title, isHighlighted }) => title && isHighlighted);

  const sortedShows = shows.sort((a, b) => differenceInMilliseconds(a.startDate, b.startDate));

  return (
    <ScrollableContainer {...props} events={shows} />
  );
   */
}

export { UpcomingShows };
