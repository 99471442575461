import styled from 'styled-components';
import ButtonPlus from './ButtonPlus';
import { BadgeType, TeamsPackModal, TeamsPackModalProps } from '../../components/TeamsPackModal';
import { useTeamsPackModal } from '../../hooks/useTeamsPackModal';

import packBoxImg from '../../assets/images/packBox.svg';
import clockImg from '../../assets/images/clock.svg';
import onlineImg from '../../assets/images/online.svg';
import inPersonImg from '../../assets/images/inPerson.svg';

interface PackProps extends TeamsPackModalProps {
  bullets?: Array<string>;
  box?: string;
}

const PackContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  margin: 1em;
`;

const PackImage = styled.img`
  margin-bottom: 39px;
  width: calc(-1.375vw + 126.4px); /* 1920: 100, 320: 122 */
`;

const PackTitle = styled.strong`
  font-family: League Spartan;
  font-weight: bold;
  text-align: center;

  margin-bottom: 1em;
  max-width: 10em;
`;

const Specs = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  margin-bottom: 1em;
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  padding: 4px;
  background-color: ${({ theme }) => theme.palette.orange};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5em;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const Duration = styled.div`
  display: flex;
  color: #9BFFED;
`;

const DurationText = styled.p`
  margin-left: 0.25em;
  white-space: nowrap;
`;

const Bullets = styled.ul`
`;

const Bullet = styled.li`
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: currentColor;
    margin-right: 0.5em;
    margin-left: -0.75em;
  }
`;

const OpenDetails = styled.div`
  align-self: flex-end;
  margin-right: 1em;
  cursor: pointer;
`;

function Pack({
  bullets = [],
  box = packBoxImg,
  ...props
}: PackProps) {
  const [teamsPackModal, teamsPackModalOpen] = useTeamsPackModal();

  return (
    <PackContainer>
      <PackImage src={box} />
      <PackTitle>
        {props.title}
      </PackTitle>
      <Specs>
        {props.badges.includes(BadgeType.FaceToFace) && (
          <IconContainer>
            <Icon src={inPersonImg} alt='Face to face' />
          </IconContainer>
        )}
        {props.badges.includes(BadgeType.Online) && (
          <IconContainer>
	    <Icon src={onlineImg} alt='Online' />
          </IconContainer>
        )}
        <Duration>
          <img src={clockImg} alt='Clock' />
          <DurationText>
            {props.duration}
          </DurationText>
        </Duration>
      </Specs>
      <Bullets>
        {bullets.map((bullet, i) => (
          <Bullet key={i}>
            {bullet}
          </Bullet>
        ))}
      </Bullets>
      <OpenDetails onClick={teamsPackModalOpen}>
        <ButtonPlus />
      </OpenDetails>
      <TeamsPackModal {...teamsPackModal} {...props} />
    </PackContainer>
  );
}

export { Pack };
