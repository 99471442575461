const menuItems = [
  {
    name: 'inicio',
    link: {
      to: 'home.html',
    },
  },
  {
    name: 'shows',
    link: {
      to: 'shows.html',
    },
  },
  {
    name: 'escuela',
    link: {
      to: 'school.html',
    },
  },
  {
    name: 'el grupo',
    link: {
      to: 'about.html',
    },
  },
  {
    name: 'empresas',
    link: {
      to: 'teams.html',
      target: '_blank',
    },
  },
  {
    name: 'contacto',
    link: {
      to: 'contact.html',
    },
  },
  {
    name: 'en directo',
    link: {
      to: 'live.html',
    },
  },
];

export {
  menuItems,
};
