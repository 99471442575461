import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { FormField } from './FormField';
import { Button, Color as ButtonColor } from '../../../components/Button';
import { SubmitButton } from '../../../components/Form';

interface FormProps {
  onSuccess?(): void;
}

const apiUrl = process.env.REACT_APP_API_URL;
const privacyPolicy = 'https://www.privacypolicies.com/live/941ff16c-7706-4122-a04a-37cc4a8ab134';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;

  @media (max-width: 630px) {
    width: 100%;
    padding: 0 calc(9.67741935483871vw + -0.9677419354838719px); /* 630: 60, 320: 30 */;
  }
`;

const FormRow = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1em;

  @media (max-width: 630px) {
    flex-direction: column;
  }
`;

const Input = styled.input`
  color: #DDDDDD;
  width: 100%;
  font-size: 17px;
`;
const TextArea = styled.textarea`
  color: #DDDDDD;
  width: 100%;
  min-height: 4em;
  font-size: 15px;
`;

interface CheckboxProps {
  checked?: boolean;
}
const Checkbox = styled.div<CheckboxProps>`
  flex: 0 0 19px;
  height: 19px;
  border: 1px solid rgba(155, 255, 237, 0.37);
  box-sizing: border-box;
  border-radius: 50%;

  position: relative;
  &::after {
    position: absolute;
    top: 2px;
    left: 2px;
    content: "";
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #9BFFED;
    transform: scale(${({ checked }) => checked ? 1 : 0});
    transition: transform 0.1s;
  }
`;

const CheckboxLabel = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.25em;

  align-items: center;

  color: #FFFFFF;

  margin-left: 1em;
`;

const Link = styled.a`
  color: white;
  font-weight: 700;
  margin-left: 0.25em;
`;

const FamilyNameField = styled(FormField)`
  flex: 1.25;

  @media (max-width: 630px) {
    margin-top: 16px;
  }
`;

const PrivacyRow = styled(FormRow)`
  cursor: pointer;
  margin: 0.5em 0 2em 0;
  @media (max-width: 630px) {
    flex-direction: row;
  }
`;

function Form(props: FormProps) {
  const [name, setName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [privacy, setPrivacy] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  useEffect(() => {
    if (!email || !message || !privacy) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [email, message, privacy]);

  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  return (
    <FormContainer>
      <FormRow>
        <FormField label="Name" value={name}>
          <Input type="text" value={name} onChange={event => setName(event.target.value)} />
        </FormField>
        <FamilyNameField label="Surname" value={familyName}>
          <Input type="text" value={familyName} onChange={event => setFamilyName(event.target.value)} />
        </FamilyNameField>
      </FormRow>
      <FormRow>
        <FormField label="email" value={email}>
          <Input type="email" value={email} onChange={event => setEmail(event.target.value)} />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField label="Company (optional)" value={company}>
          <Input type="text" value={company} onChange={event => setCompany(event.target.value)} />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField label="Subject" value={subject}>
          <Input type="text" value={subject} onChange={event => setSubject(event.target.value)} />
        </FormField>
      </FormRow>
      <FormRow style={{ marginBottom: '1em' }}>
        <FormField label="Message" value={message} textArea>
          <TextArea value={message} onChange={event => setMessage(event.target.value)} />
        </FormField>
      </FormRow>
      <PrivacyRow onClick={() => setPrivacy(x => !x)}>
        <Checkbox checked={privacy} />
        <CheckboxLabel>
          I have read and agree to the<Link href={privacyPolicy} target="_blank">privacy policy</Link>
        </CheckboxLabel>
      </PrivacyRow>
      <SubmitButton
        disabled={submitDisabled}
        submitting={submitting}
        success={submitSuccess}
        borderColor='#FD5D27'
        fillColor='#FD5D27'
        from={0.9}
        to={0.7}
        onClick={() => {
          setSubmitDisabled(true);
          setSubmitting(true);

          Promise.all([
            // Artifically delay the response to display the animation
            new Promise(resolve => setTimeout(resolve, 2000)),
            fetch(`${apiUrl}/sendContactMessage`, {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                name,
                familyName,
                email,
                company,
                category: 'teams',
                subject,
                message,
                newsletter: false,
                privacy,
              })
            })
          ]).then(success => {
            setSubmitting(false);
            setSubmitSuccess(true)
            if (typeof props.onSuccess === 'function') {
              props.onSuccess();
            }
          });
        }}
      >
        send
      </SubmitButton>
    </FormContainer>
  );
}

export { Form };
