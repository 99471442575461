import { createContext, useMemo } from 'react';
import { Environment } from '../types';
import { getDateFingerprint, useFingerprint } from '../controllers/fingerprint';

import type { FC } from 'react';
import type { Context as RenderContext, Storage } from '../types';

interface ServicesProps {
  context?: RenderContext;
  environment: Environment;
}

interface ServicesContextType {
  fingerprint: string;
  storage: Storage
  data: RenderContext['data'];
}

const defaultContext = {
  fingerprint: getDateFingerprint('defaultContext'),
  storage: {
    getItem: () => null,
    setItem: () => {},
    removeItem: () => {},
  },
  data: {
    teamupEvents: [],
    shows: []
  },
};

const ServicesContext = createContext<ServicesContextType>(defaultContext);

const Services: FC<ServicesProps> = ({ context: renderContext, environment, children }) => {
  const fingerprint = useFingerprint(environment);

  const storage = useMemo(() => (
    environment === Environment.Static
      ? {
        getItem: () => null,
        setItem: () => {},
        removeItem: () => {},
      }
      : window.localStorage
  ), [environment, renderContext]);

  const contextData = useMemo(() => (
    environment === Environment.Static
      ? renderContext?.data
      : window.emgware?.data
  ), [environment, renderContext]);

  const context = useMemo(() => ({
    fingerprint,
    storage,
    data: contextData || defaultContext.data,
  }), [contextData, fingerprint, storage]);

  return (
    <ServicesContext.Provider value={context}>
      {children}
    </ServicesContext.Provider>
  );
};

export { Services, ServicesContext };
export type { ServicesContextType, Storage };
