import styled from 'styled-components';
import { CircularPicture } from '../../components/CircularPicture';

import teamBuildingWorkshop from './assets/teamBuildingWorkshop.jpg';

interface CreativePotentialProps {
}

const CreativePotentialContainer = styled.div`
  color: white;
  display: flex;
  align-items: center;
  margin: 1em calc(22.48062015503876vw + -111.62790697674419px); /* 1920: 320, 630: 30 */
`;

const TextContainer = styled.div`
  margin-right: calc(15.503875968992247vw + -97.67441860465117px); /* 1920: 200, 630: 0 */

  @media (max-width: 640px) {
    margin: 0 40px;
  }
`;

const Title = styled.p`
  font-family: League Spartan;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0.5em;
  color: ${({ theme }) => theme.palette.orange};
`;

const Description = styled.p`
  font-size: 16px;
  margin: 1em 0;
`;

const Picture = styled(CircularPicture)`
  flex: 0 0 calc(18.217054263565892vw + 185.2325581395349px); /* 1920: 535, 630: 300 */
  height: calc(18.217054263565892vw + 185.2325581395349px); /* 1920: 535, 630: 300 */
  @media (max-width: 640px) {
    display: none;
  }
`;

function CreativePotential(_: CreativePotentialProps) {
  return (
    <CreativePotentialContainer>
      <TextContainer>
        <Title>
          Unleash the creative potential of your team
        </Title>
        <Description>
          If you think your work is the only place where you are required to think on your feet, adapt to changes and work collaboratively to create something out of nothing, think twice! Improvisers do this on stage all the time.
        </Description>
        <Description>
          Our learning sessions combine years of experience -both on scene and in the talent development world- and are designed to translate the same collaborative principles that make Improv groups successful, into your world and your team.
        </Description>
      </TextContainer>
      <Picture src={teamBuildingWorkshop} alt='Team building workshop' />
    </CreativePotentialContainer>
  );
}

export { CreativePotential };
