import { Helmet } from 'react-helmet-async';

function Head() {
  return (
    <Helmet>
      <title>Improving your team collaboration | ESIMPRO </title>
      <meta name="description" content="Unleash your team's collaboration, resilience and innovation." />
    </Helmet>
  );
}

export default Head;
