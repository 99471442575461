import styled from 'styled-components';

interface ButtonPlusProps {
}

const Svg = styled.svg`
  stroke: #FFFFFF;
  stroke-width: 1.5;
  fill: none;
  width: 33px;
  height: 33px;

  margin-top: 1em;
`;

function ButtonPlus(_: ButtonPlusProps) {
  return (
    <Svg viewBox='0 0 50 50'>
      <ellipse cx='25' cy='25' rx='24.25' ry='24.25' />
      <line x1='15' y1='25' x2='35' y2='25' />
      <line x1='25' y1='15' x2='25' y2='35' />
    </Svg>
  );
}

export default ButtonPlus;
