import styled from 'styled-components';
import { CircularPicture } from '../../components/CircularPicture';

import aleGon from './assets/aleGon.png';

interface CoachesProps {
}

const CoachesContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: calc(5vw + 64px); /* 1920: 160, 320: 80 */
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-image: linear-gradient(90deg, #9BFFED00 0, #9BFFEDAA 50%, #9BFFED00 100%);
  }
`;

const Content = styled.div`
  display: flex;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  justify-content: center;
  font-size: calc(0.25vw + 11.2px); /* 1920: 16, 320: 12 */
`;

const TextAle = styled(Text)`
  position: relative;
  top: calc(5.9375vw + -89px); /* 1920: 25, 320: -70 */
  left: calc(-2.1875vw + 62px); /* 1920: 20, 320: 55 */
`;

const TextGon = styled(Text)`
  position: relative;
  top: calc(0.625vw + -92px); /* 1920: -80, 320: -90 */
  left: calc(1.75vw + -63.6px); /* 1920: -30, 320: -58 */
`;

const Name = styled.strong`
  font-family: League Spartan;
  font-weight: 700;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 0.75em;
  text-align: center;
  line-height: 1.5em;
`;

const Description = styled.p`
  font-family: Quicksand;
  font-weight: 400;
  font-size: 1.125em;
  text-align: center;

  max-width: 14em;
`;

const Picture = styled.img`
  width: min(558px, calc(19.25vw + 188.4px)); /* 1920: 558, 320: 250 */
  height: min(408px, calc(14.124999999999998vw + 136.8px)); /* 1920: 408, 320: 182 */
`;

function Coaches(_: CoachesProps) {
  return (
    <CoachesContainer>
      <Content>
        <TextAle>
          <Name>
            Alejandro Goiri
          </Name>
          <Description>
            Coach, improviser, and psychologist
          </Description>
        </TextAle>
        <Picture src={aleGon} alt='Alejandro Goiri y Gonzalo Piñán' />
        <TextGon>
          <Name>
            Gonzalo Piñán
          </Name>
          <Description>
            Teacher, improviser, musician and psycologist
          </Description>
        </TextGon>
      </Content>
    </CoachesContainer>
  );
}

export { Coaches };
