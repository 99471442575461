import styled from 'styled-components';

import effectiveListening from './effectiveListening.svg';
import trust from './trust.svg';
import coCreation from './coCreation.svg';
import selfDiscovery from './selfDiscovery.svg';
import spotaniety from './spotaniety.svg';
import resilience from './resilience.svg';

interface ValueProps {
  image: string;
  title: string;
  body: string;
}

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(11vw + 140.8px); /* 1920: 352, 320: 176 */
  position: relative;
  margin-bottom: 3em;
`;

const Image = styled.img`
  position: relative;
`;

const Title = styled.p`
  font-family: League Spartan;
  font-weight: bold;
  font-size: calc(0.375vw + 17.8px); /* 1920: 25, 320: 19 */
  color: #9BFFED;
  margin-bottom: 0.5em;
`;

const Description = styled.p`
  font-size: calc(0.1875vw + 15.4px); /* 1920: 19, 320: 16 */
  color: #FFFFFF;
`;

function Value(_: ValueProps) {
  return (
    <ValueContainer>
    </ValueContainer>
  );
}

const EffectiveListeningContainer = styled(ValueContainer)`
  left: calc(13vw + -9.600000000000001px); /* 1920: 240, 320: 32 */
  margin-top: calc(-3vw + 41.6px); /* 1920: -16, 320: 32 */
`;
const EffectiveListeningImage = styled(Image)`
  width: calc(2.9375vw + 33.6px); /* 1920: 90, 320: 43 */

  left: calc(-4.496124031007752vw + -41.674418604651166px); /* 1920: -128, 630: -70 */
  top: calc(2.1705426356589146vw + 46.325581395348834px); /* 1920: 88, 630: 60 */
  @media (max-width: 630px) {
    left: 64px;
    top: -8px;
  }
`;
Value.EffectiveListening = () => (
  <EffectiveListeningContainer>
    <EffectiveListeningImage src={effectiveListening} alt='Ear' />
    <Title>Effective listening</Title>
    <Description>Building together starts with listening to each other.</Description>
  </EffectiveListeningContainer>
);

const TrustContainer = styled(ValueContainer)`
  right: calc(18vw + -25.599999999999994px); /* 1920: 320, 320: 32 */
  margin-bottom: 4em;
`;
const TrustImage = styled(Image)`
  width: calc(2.5625vw + 35.8px); /* 1920: 85, 320: 44 */

  right: calc(4.496124031007752vw + 41.674418604651166px); /* 1920: 128, 630: 70 */
  top: 80px;
  @media (max-width: 630px) {
    right: -80px;
    top: 16px;
  }
`;
Value.Trust = () => (
  <TrustContainer>
    <TrustImage src={trust} alt='Handshake' />
    <Title>Trust</Title>
    <Description>The glue that sticks teams together.</Description>
  </TrustContainer>
);

const CoCreationContainer = styled(ValueContainer)`
  width: calc(17vw + 89.6px); /* 1920: 416, 320: 144 */
  left: calc(15vw + -16px); /* 1920: 272, 320: 32 */
  margin-bottom: 5em;
`;
const CoCreationImage = styled(Image)`
  width: calc(4.125vw + 50.8px); /* 1920: 130, 320: 64 */
  right: calc(6vw + 44.8px); /* 1920: 160, 320: 64 */
  top: calc(2vw + 25.6px); /* 1920: 64, 320: 32 */
`;
const CoCreationTitle = styled(Title)`
  margin-left: 1em;
`;
Value.CoCreation = () => (
  <CoCreationContainer>
    <CoCreationImage src={coCreation} alt='Pile of bricks' />
    <CoCreationTitle>Co-creation</CoCreationTitle>
    <Description>If we all bring one brick, together, we can build a castle.</Description>
  </CoCreationContainer>
);

const SelfDiscoveryContainer = styled(ValueContainer)`
  right: calc(18vw + -41.599999999999994px); /* 1920: 304, 320: 16 */
  margin-bottom: 1em;
`;
const SelfDiscoveryImage = styled(Image)`
  width: calc(2.25vw + 38.8px); /* 1920: 82, 320: 46 */

  right: calc(4.108527131782946vw + 49.116279069767444px); /* 1920: 128, 630: 75 */
  top: calc(1.627906976744186vw + 64.74418604651163px); /* 1920: 96, 630: 75 */
  @media (max-width: 630px) {
    right: -64px;
    top: -16px;
  }
`;
const SelfDiscoveryTitle = styled(Title)`
  margin-left: 0.25em;
`;
Value.SelfDiscovery = () => (
  <SelfDiscoveryContainer>
    <SelfDiscoveryImage src={selfDiscovery} alt='Map within a head' />
    <SelfDiscoveryTitle>Self-discovery</SelfDiscoveryTitle>
    <Description>Know yourself better and, from your self, reach out to the stars.</Description>
  </SelfDiscoveryContainer>
);

const SpotaneityContainer = styled(ValueContainer)`
  left: calc(11vw + 28.799999999999997px); /* 1920: 240, 320: 64 */
`;
const SpotaneityImage = styled(Image)`
  width: calc(2.5vw + 52px); /* 1920: 100, 320: 60 */
  right: calc(4.5vw + 57.6px); /* 1920: 144, 320: 72 */
  top: calc(4vw + 19.2px); /* 1920: 96, 320: 32 */
`;
Value.Spotaneity = () => (
  <SpotaneityContainer>
    <SpotaneityImage src={spotaniety} alt='Lightning bolt' />
    <Title>Spotaneity</Title>
    <Description>Think quickly - and effectively- on your feet.</Description>
  </SpotaneityContainer>
);

const ResilienceContainer = styled(ValueContainer)`
  right: calc(18vw + -25.599999999999994px); /* 1920: 320, 320: 32 */
`;
const ResilienceImage = styled(Image)`
  width: calc(1.875vw + 52px); /* 1920: 88, 320: 58 */

  right: calc(2.4806201550387597vw + 64.37209302325581px); /* 1920: 112, 630: 80 */
  top: 80px;
  @media (max-width: 630px) {
    right: -176px;
    top: 25px;
  }
`;
Value.Resilience = () => (
  <ResilienceContainer>
    <ResilienceImage src={resilience} alt='Pile of lines being squashed by an arrow' />
    <Title>Resilience</Title>
    <Description>Come what may the show must go on and you’ll be ready.</Description>
  </ResilienceContainer>
);

export { Value };
