import styled from 'styled-components';
import { Form } from './Form';

interface ContactUsProps {
}

const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.strong`
  font-family: League Spartan;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.25em;
  text-align: center;
  margin-bottom: 1em;
`;

function ContactUs(_: ContactUsProps) {
  return (
    <ContactUsContainer>
      <Title>
        Contact us!
      </Title>
      <Form />
    </ContactUsContainer>
  );
}

export { ContactUs };
