import { ReactNode, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Badge, BadgeType } from './Badge';
import { Modal, ModalProps } from '../Modal';
import { Button } from '../Button';
import { useServices } from '../../hooks/useServices';
import { LayoutContext } from '../../providers/Layout';
import { Flags } from '../../pages/School/Event/Flags';

import clock from './clock.svg';

export interface TeamsPackModalPropsInternal extends ModalProps {
  title: string;
  titleLogo?: ReactNode;
  duration: string;
  description?: string;
  useCases: string;
  learningOutcomes: Array<string>;
  badges: Array<BadgeType>;
}

export type TeamsPackModalProps = Omit<TeamsPackModalPropsInternal, keyof ModalProps>;

const TeamsPackModalContainer = styled.div`
  position: absolute;
  bottom: calc(3.125vw + -10px); /* 1920: 50, 320: 0 */
  left: 50%;
  max-width: 750px;
  background-color: ${({ theme }) => theme.palette.background};
  transform: translateX(-50%);
  box-shadow: 0px 5px 95px -10px rgba(214, 224, 255, 0.12);
  border-radius: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 5em;
  padding-bottom: calc(4.375vw + -4px); /* 1920: 80, 320: 10 */
  max-height: 100vh;
  overflow-y: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 275px;
  margin: 0.5em 0;
`;

const RowText = styled(Row)`
  @media (max-width: 800px) {
    flex-direction: column;
  }
  padding-bottom: 2em;
`;

const TitleContainer = styled.div`
  display: flex;
  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: calc(0.43750000000000006vw + 16.6px); /* 1920: 25, 320: 18 */
  line-height: 1.25em;

  margin: 0 1em 0 0;
  @media (max-width: 1350px) {
    margin: 0 0 0.5em 0;
  }
`;

const TitleLogo = styled.span`
`;

const Badges = styled.div`
  display: flex;
`;

const Duration = styled.div`
  color: ${({ theme }) => theme.palette.lightBlue};
  font-size: calc(0.25vw + 11.2px); /* 1920: 16, 320: 12 */
  line-height: 1.25em;
  font-weight: 400;
  margin-bottom: calc(1.25vw + 6px); /* 1920: 30, 320: 10 */
`;

const Clock = styled.img`
  position: relative;
  top: 2px;
  margin-right: 0.25em;
`;

const Description = styled.div`
  font-size: calc(0.25vw + 11.2px); /* 1920: 16, 320: 12 */
  font-weight: 400;
  line-height: 1.25em;

  margin: 0 1em 0 0;
  @media (max-width: 1350px) {
    margin: 0 0 0.5em 0;
  }
`;

const SectionTitle = styled.div`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: calc(0.25vw + 13.2px); /* 1920: 18, 320: 14 */
  line-height: 1.25em;
  margin-bottom: 1em;
`;

const UseCases = styled.div`
  flex: 1;
  font-size: calc(0.25vw + 11.2px); /* 1920: 16, 320: 12 */
  font-weight: 400;
  padding-right: 15%;
  @media (max-width: 800px) {
    margin-bottom: 2em;
  }
`;

const LearningOutcomes = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const LearningOutcome = styled.span`
  font-size: calc(0.25vw + 11.2px); /* 1920: 16, 320: 12 */
  font-weight: 400;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0.5em;
    left: -0.75em;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: white;
    margin-right: 0.5em;
  }
`;

function TeamsPackModal({
  open,
  onClose,
  title,
  titleLogo,
  badges,
  duration,
  useCases,
  learningOutcomes,
  description,
}: TeamsPackModalPropsInternal) {
  const { modalPortal } = useContext(LayoutContext);
  const [email, setEmail] = useState('');

  return (
    <Modal open={open} onClose={onClose}>
      <TeamsPackModalContainer>
        <Row>
          <TitleContainer>
            <Title>
              {title}
            </Title>
            {titleLogo && (
              <TitleLogo>
                {titleLogo}
              </TitleLogo>
            )}
          </TitleContainer>
          <Badges>
            {badges.map(badge => (
              <Badge key={badge} type={badge} />
            ))}
          </Badges>
        </Row>
        <Row>
          <Duration>
            Recommended duration:<br /><Clock src={clock} alt='Clock' />{duration}
          </Duration>
        </Row>
        {description && (
          <Row>
            <Description>
              {description}
            </Description>
          </Row>
        )}
        <RowText>
          <UseCases>
            <SectionTitle>Use cases</SectionTitle>
            {useCases}
          </UseCases>
          <LearningOutcomes>
            <SectionTitle>Learning outcomes</SectionTitle>
            {learningOutcomes.map((learningOutcome, i) => (
              <LearningOutcome key={i}>
                {learningOutcome}
              </LearningOutcome>
            ))}
          </LearningOutcomes>
        </RowText>
      </TeamsPackModalContainer>
    </Modal>
  );
}

export { TeamsPackModal };
