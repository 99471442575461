import styled from 'styled-components';
import { Dots } from './Dots';
import { Person } from './Person';
import { CircularPicture } from '../../components/CircularPicture';

import group from './assets/group.jpg';
import afterShow from './assets/afterShow.jpg';

interface TimelineProps {
}

const TimelineContainer = styled.div`
  position: relative;
`;

const Year = styled.p`
  font-family: League Spartan;
  font-weight: bold;
  font-size: calc(3.125vw + 20px);
  text-align: center;

  color: #9BFFED;
`;

const P2 = styled.p`
  font-weight: 400;
  font-size: calc(0.25vw + 15.2px);
  width: calc(12.5vw + 160px);

  color: #FFFFFF;

  margin-top: 2em;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const SuperBold = styled.span`
  font-weight: 700;
  font-size: 22px;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;
const Block1 = styled(Block)`
  position: relative;

  @media (min-width: 631px) {
    top: 4em;
    left: -17vw;
  }
`;
const Picture1 = styled.div`
  position: relative;
  height: calc(12.625vw + 155.6px);
  width: calc(12.625vw + 155.6px);

  @media (min-width: 631px) {
    top: -9em;
    right: -12em;
  }
  @media (max-width: 630px) {
    margin-top: 4em;
    left: 30px;
  }
`;
const Picture1Description = styled.p`
  font-weight: 400;
  font-size: calc(-0.125vw + 16.4px);
  text-align: right;

  color: #9BFFED;

  position: relative;
  width: 210px;

  @media (min-width: 631px) {
    top: -160px;
    right: calc(-6.976744186046512vw + -146.04651162790697px);
  }
  @media (max-width: 630px) {
    margin-top: 2em;
    left: 10px;
  }
`;


const Block2 = styled(Block)`
  position: relative;

  @media (min-width: 631px) {
    top: calc(-9.154383242823895vw - 192.23584173778121px);
    right: calc(-10.861132660977502vw - 111.46625290923197px);
  }
`;
const Picture2 = styled.div`
  position: relative;
  height: calc(15.0625vw + 198.8px);
  width: calc(15.0625vw + 198.8px);

  @media (min-width: 631px) {
    left: -20vw;
  }
  @media (max-width: 630px) {
    margin-top: 10em;
  }
`;

const Block3 = styled(Block)`
  position: relative;
  margin: 11em 0 calc(3.75vw + 88px) 0;
`;

const PersonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  & > * {
    margin: calc(0.5625vw + 5.2px) calc(0.6875vw + 4.8px); // 16|7, 18|7
  }
  margin-top: 1em;
`;

function Timeline(_: TimelineProps) {
  return (
    <TimelineContainer>
      <Dots />
      <Block1>
        <Year>
          2014
        </Year>
        <P2>
          En 2014 fundamos el grupo <Bold>Berlín&nbsp;ES&nbsp;Impro</Bold>, el primer grupo de impro en español de toda Alemania, yeah! Así empezó todo.
        </P2>
      </Block1>
      <Picture1>
        <CircularPicture
          src={group}
          alt='Grupo'
        />
      </Picture1>
      <Picture1Description>
        Alejandro Goiri, MJ Gallego, María Bautista, Alfonso Garzas, Rocío Peláez y Gonzalo Piñán: Fundadores de <Bold>Berlín ES Impro</Bold>.
      </Picture1Description>
      <Picture2>
        <CircularPicture
          src={afterShow}
          alt='Después de un show'
        />
      </Picture2>
      <Block2>
        <P2>
          Estuvimos en bodas, miles de espectáculos en bares, luego pasamos también a las salas de teatro, participamos en numerosos festivales de impro y...
        </P2>
      </Block2>
      <Block3>
        <Year>
          2020
        </Year>
        <P2>
          en 2020 nos reinventamos y nos formamos como ESIMPRO, un colectivo de impro en español con sede en Berlín dispuesto a hacer de la impro mucho más.
        </P2>
      </Block3>
    </TimelineContainer>
  );
}

export { Timeline };
