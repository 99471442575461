import styled from 'styled-components';
import { CookieManager } from './CookieManager';
import { Facebook } from './Facebook';
import { Instagram } from './Instagram';

interface FooterProps {
}

const FooterContainer = styled.div<FooterProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 0 7%;
  padding-bottom: calc(1.0625vw + 11.6px); /* 1920: 32, 320: 15 */
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 630px) {
    flex-direction: column;
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: white;
`;

const MailTo = styled.a`
  color: inherit;
`;

const LinksContainer = styled.div`
  @media (max-width: 630px) {
    margin-top: 1em;
  }
`;

const FacebookLink = styled.a`
  margin-right: 1em;
  color: white;
`;

const InstagramLink = styled.a`
  margin-right: 0.85em;
  color: white;
`;

const CookieManagerStyled = styled(CookieManager)`
  width: 26px;
  height: 26px;
  vertical-align: top;
`;

function Footer(_: FooterProps) {
  return (
    <FooterContainer>
      <Text>
        ESIMPRO 2022 | Development:&nbsp;<MailTo href='mailto:eloi@emgware.com?subject=ESIMPRO website'>EmgWare</MailTo>&nbsp;/&nbsp;Design:&nbsp;louromera
      </Text>
      <LinksContainer>
        <FacebookLink href='https://www.facebook.com/ESIMPRO/' target='_blank'>
          <Facebook />
        </FacebookLink>
        <InstagramLink href='https://instagram.com/berlin_es_impro' target='_blank'>
          <Instagram />
        </InstagramLink>
        <CookieManagerStyled />
      </LinksContainer>
    </FooterContainer>
  );
}

export { Footer };
