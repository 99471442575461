import styled from 'styled-components';
import ShowFormat from './ShowFormat';
import { ShowFormatTypes } from './types';

import banco from './assets/banco.jpg';
import sitcom from './assets/sitcom.jpg';
import estacion from './assets/estacion.jpg';
import checkpoint from './assets/checkpoint.jpg';
import laboratorio from './assets/laboratorio.jpg';
import sesionTriple from './assets/sesionTriple.jpg';
import queTeCuentas from './assets/queTeCuentas.jpg';
import hotel from './assets/hotel.jpg';

interface ShowFormatsProps {
}

const ShowFormatsContainer = styled.div`
  margin: calc(2.1875vw + 23px) 0 100px;
  display: grid;

  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1042px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 784px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 526px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 785px) {
    padding: 0 calc(15.418502202643172vw + -121.0352422907489px); /* 1920: 175, 785: 0 */
  }
`;

function ShowFormats(_: ShowFormatsProps) {
  return (
    <ShowFormatsContainer>
      <ShowFormat
        id={ShowFormatTypes.Banco}
        title='Historias de un banco'
        description='Un día en la vida de un banco y los personajes que se reúnen alrededor de él. Este fue nuestro primer gran formato y ahora es un clásico de ESIMPRO.'
        image={{
          src: banco,
          alt: 'Historias de un banco',
        }}
      />
      <ShowFormat
        id={ShowFormatTypes.Sitcom}
        title='Sitcom'
        description='La comedia de situación de ESIMPRO, al estilo de comedias de la televisión como "Big Bang Theory"", "Los Simpsons" o "Friends", pero totalmente improvisada.'
        image={{
          src: sitcom,
          alt: 'Sitcom',
        }}
      />
      <ShowFormat
        id={ShowFormatTypes.Estacion}
        title='Estación'
        description='Entre el tumulto de una estación, dos personas a la espera de un tren. Ellos nos contarán su pasado lleno de realismo y magia. Un cruce de caminos, o mejor dicho de railes donde tú imaginación es lo más importante.'
        image={{
          src: estacion,
          alt: 'Estación',
        }}
      />
      <ShowFormat
        id={ShowFormatTypes.Checkpoint}
        title='Checkpoint'
        description='Las costumbres, el nombre de un país inventado y el espíritu migrante de nuestros protagonistas les hará ir descubriendo este improvisado país a partir de las sugerencias del público.'
        image={{
          src: checkpoint,
          alt: 'Checkpoint',
        }}
      />
      <ShowFormat
        id={ShowFormatTypes.Laboratorio}
        title='Laboratorio'
        description='El tradicional y rebelde show de ESIMPROEscuela. Entre la locura de los estudiantes más avanzados y el frenesí del público creamos las mejores fórmulas en este laboratorio improvisado.'
        image={{
          src: laboratorio,
          alt: 'Laboratorio',
        }}
      />
      <ShowFormat
        id={ShowFormatTypes.SesionTriple}
        title='Sesión triple'
        description='Un tríptico improvisado con tres historias en tres estilos cinematográficos diferentes. ¡Tres películas en una! ¿Cuándo ir al cine fue tan rentable? ¡Y además tú eliges el título de cada película!'
        image={{
          src: sesionTriple,
          alt: 'Sesión triple',
        }}
      />
      <ShowFormat
        id={ShowFormatTypes.QueTeCuentas}
        title='¿Qué te cuentas?'
        description='Una palabra del público, un monólogo aún sin escribir y escenas improvisadas. Cualquier cosa se puede esperar de este hilarante “micro abierto” de ESIMPRO.'
        image={{
          src: queTeCuentas,
          alt: '¿Qué te cuentas?',
        }}
      />
      <ShowFormat
        id={ShowFormatTypes.Hotel}
        title='Hotel'
        description='El formato en inglés de ESIMPRO, donde abriremos la puerta de una habitación de hotel para  adentrarnos en la intimidad de las vidas y experiencias de sus huéspedes.'
        image={{
          src: hotel,
          alt: 'Hotel',
        }}
        language='en'
      />
    </ShowFormatsContainer>
  );
}

export default ShowFormats;
