import styled from 'styled-components';

interface SectionHeaderProps {
  title: string;
  description: string;
  separator?: boolean;
  variant?: 'yellow';
}

const SectionHeaderContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 0px calc(8.75vw + -28px); /* 1920: 140, 320: 0 */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 28px;
`;

const Title = styled.div`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  line-height: 1.25em;
  text-align: center;
  color: white;
  margin-bottom: 0.5em;
`;

const Description = styled.span<{ variant?: 'yellow' }>`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 1.25em;
  text-align: center;
  color: ${({ theme, variant }) => variant === 'yellow' ? theme.palette.yellowSchool : '#FD5D27' };
`;

const Separator = styled.span`
  height: 1px;
  width: 100%;
  background-image: linear-gradient(90deg, #9BFFED00 0, #9BFFEDAA 50%, #9BFFED00 100%);
  margin-top: 1em;
`;

function SectionHeader(props: SectionHeaderProps) {
  return (
    <SectionHeaderContainer>
      <Title>
        {props.title}
      </Title>
      <Description variant={props.variant}>
        {props.description}
      </Description>
      {props.separator && (
        <Separator />
      )}
    </SectionHeaderContainer>
  );
}

export { SectionHeader };
